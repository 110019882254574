import './App.css';
import GenerateResult from './Preview';

function App() {
  return (
    <div className="App">
      <GenerateResult/>
    </div>
  );
}

export default App;
