import React, { useCallback, useEffect, useState } from "react";

/* components */
import Button from "./components/general/Button";
import ImageViewer from "react-simple-image-viewer";

/* styles */
import styles from "./styles/auth/Box.module.css";
import textStyles from "./styles/general/Text.module.css";

/* assets */
import shoeImg from "./assets/shoe.png";
import axios from "axios";

export default function GenerateResult() {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");

  const [data, setData] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axios.get(
          `https://katch-358301.ew.r.appspot.com/v/${id}`
        );

        if (!resp.data.error) {
          setData(resp.data);
          setLoading(false);
        } else {
          throw new Error(resp.data.error);
        }
      } catch (e) {
        console.log(e.message);
      } finally {
      }
    })();
  }, []);

  const [isPrev, setIsPrev] = useState(false);
  const [prevImg, setPrevImg] = useState("");

  const imageNames = [
    "top",
    "bottom",
    "left",
    "right",
    "front",
    "back",
    "box",
    "accessories",
  ];

  // const imageNames = [
  //   "https://cdn.discordapp.com/attachments/795860683497275433/1026073306136313947/unknown.png",
  //   "https://cdn.discordapp.com/attachments/795860683497275433/1026073306136313947/unknown.png",
  //   "https://cdn.discordapp.com/attachments/795860683497275433/1026073306136313947/unknown.png",
  //   "https://sneakernews.com/wp-content/uploads/2021/12/air-jordan-8-oregon-ducks-stockx-dropx-release-info-1.jpg",
  //   "https://cdn.discordapp.com/attachments/795860683497275433/1026073306136313947/unknown.png",
  // ];

  return loading ? (
    <div className={styles.loader}>
      <img alt="loading..." src={require("./assets/loader.png")} />
    </div>
  ) : (
    <div className={`${styles.box} ${styles.preview}`}>
      {/* <div className={styles.bg}></div> */}
      <div>
        <span className={styles.wrapper}>
          <div className={styles.shoeImgContainer}>
            <div>
              <img
                src={data?.product?.imgUrl}
                className={styles.shoeImg}
                alt="shoeImg"
              />
            </div>

            <div className={styles.shoeDetails}>
              <div className={textStyles.light}>{data?.product?.name}</div>
              <div className={textStyles.dark}>{data?.product?.colorway}</div>
            </div>
          </div>
        </span>
        {/* 
        <div className={styles.prevContainer}>
          {isPrev && (
            <img
              src="https://cdn.discordapp.com/attachments/795860683497275433/1026073306136313947/unknown.png"
              alt="shoeImges"
              className={styles.prevImg}
              onClick={() => {
                setIsPrev((prev) => !prev);
                setPrevImg();
              }}
            />
          )}
        </div> */}

        {isViewerOpen && (
          <ImageViewer
            src={imageNames.map((e, i) => {
              if (data?.authData?.[e]) {
                return data?.authData?.[e];
              }
            })}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            leftArrowComponent={<div className={styles.none}></div>}
            rightArrowComponent={<div className={styles.none}></div>}
            closeComponent={<div className={styles.close}></div>}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )}

        <span className={styles.wrapper}>
          <div className={styles.imagesBox}>
            {imageNames.map((e, i) => {
              if (data?.authData?.[e]) {
                return (
                  <img
                    key={i}
                    src={data?.authData?.[e]}
                    alt="shoeImges"
                    className={styles.imageBox}
                    onClick={() => openImageViewer(i)}
                  />
                );
              }
            })}
          </div>
        </span>
      </div>
      <div>
        <div className={`flex al-ce jc-sp ${styles.value}`}>
          <div>Unique Code:</div>
          <div className={`${textStyles.light} ${textStyles.small}`}>{id} </div>
        </div>
        <div className={`flex al-ce jc-sp ${styles.value}`}>
          <div>Size:</div>
          <div className={textStyles.dark}>US {data?.product?.size}</div>
        </div>

        {/* <div className={`flex al-ce jc-sp ${styles.value}`}>
          <div>Price:</div>
          <div className={textStyles.dark}>{data?.product?.subtitle}</div>
        </div> */}

        <div className={`flex al-ce jc-sp ${styles.value}`}>
          <div>SKU:</div>
          <div className={textStyles.dark}>{data?.product?.sku}</div>
        </div>

        {/* <div className={`flex al-ce jc-sp ${styles.value}`}>
          <div>Purchase Date:</div>
          <div className={textStyles.dark}>{data?.product?.sku}</div>
        </div> */}

        <div className={`${styles.thank}`}>
          Owned by
          <br /> “{data?.customer?.name}”
        </div>

        <div className={`${styles.green} ${styles.verified}`}>
          Authenticity Verified!
        </div>

        <div className={`flex al-ce jc-ce ${styles.bottom}`}>
          <svg
            width="58"
            height="16"
            viewBox="0 0 58 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 15.4895V0.875616L1.86444 0H1.96492V7.33744L7.2903 0H7.39078L9.11567 0.809113L4.73925 6.59483C5.64915 6.59483 6.53113 6.77217 7.39078 7.13239C8.87563 7.74754 9.61806 8.65086 9.61806 9.83128V14.6139L7.75362 15.4895H7.65314V9.83128C7.65314 9.38239 7.25681 9.00554 6.46414 8.68966C5.84452 8.46244 5.26956 8.34606 4.73925 8.34606C4.20895 8.34606 3.67864 8.44581 3.1595 8.64532C2.36125 8.95012 1.96492 9.3436 1.96492 9.82574V14.6084L0.100479 15.4895H0Z"
              fill="white"
            />
            <path
              d="M12.1412 15.4895V3.40826C12.1412 2.22229 12.8836 1.31897 14.3685 0.709364C15.2002 0.365768 16.1101 0.19397 17.0981 0.19397C18.0862 0.19397 18.9961 0.365768 19.8278 0.709364C21.3127 1.31897 22.0551 2.21675 22.0551 3.40826V14.6139L20.1906 15.4895H20.0902V8.6287H14.1061V14.6084L12.2417 15.484H12.1412V15.4895ZM14.1061 6.86084H20.0902V3.40826C20.0902 2.95936 19.6938 2.58252 18.9012 2.26663C18.3541 2.0505 17.7512 1.9452 17.0981 1.9452C16.445 1.9452 15.8421 2.0505 15.2951 2.26663C14.5024 2.57697 14.1061 2.95936 14.1061 3.40826V6.8553V6.86084Z"
              fill="white"
            />
            <path
              d="M27.5982 15.4895V2.16134H23.4004V2.06158L24.1931 0.387939H33.7609V0.487693L32.9682 2.16134H29.5631V14.6139L27.6987 15.4895H27.5982V15.4895Z"
              fill="white"
            />
            <path
              d="M44.6182 9.74262V12.0813C44.6182 13.2672 43.8758 14.1706 42.3909 14.7802C41.5592 15.1238 40.6493 15.2956 39.6612 15.2956C38.6732 15.2956 37.7633 15.1238 36.9316 14.7802C35.4467 14.1706 34.7043 13.2728 34.7043 12.0813V3.40826C34.7043 2.2223 35.4467 1.31897 36.9316 0.709364C37.7633 0.365768 38.6732 0.19397 39.6612 0.19397C40.6493 0.19397 41.5592 0.365768 42.3909 0.709364C43.8758 1.31897 44.6182 2.21675 44.6182 3.40826V4.87131L42.7538 5.74693H42.6533V3.40826C42.6533 2.96491 42.2569 2.58806 41.4643 2.26663C40.9172 2.0505 40.3144 1.9452 39.6612 1.9452C39.0081 1.9452 38.4053 2.0505 37.8582 2.26663C37.0655 2.57697 36.6692 2.95936 36.6692 3.40826V12.0757C36.6692 12.5191 37.0655 12.8959 37.8582 13.2174C38.4053 13.4335 39.0081 13.5388 39.6612 13.5388C40.3144 13.5388 40.9172 13.4335 41.4643 13.2174C42.2569 12.907 42.6533 12.5246 42.6533 12.0757V10.6127L44.5177 9.73707H44.6182V9.74262Z"
              fill="white"
            />
            <path
              d="M47.7386 15.4895V0.875616L49.6031 0H49.7036V6.86084H55.4923V0.875616L57.3567 0H57.4572V14.6139L55.5927 15.4895H55.4923V8.62869H49.7036V14.6084L47.8391 15.484H47.7386V15.4895Z"
              fill="white"
            />
          </svg>
          <span>by</span>
          <svg
            width="98"
            height="18"
            viewBox="0 0 98 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.8219 3.04804C26.6209 2.843 26.5204 2.59915 26.5204 2.31098C26.5204 2.0228 26.6209 1.77896 26.8219 1.57391C27.0228 1.36886 27.2685 1.2691 27.5531 1.2691C27.8378 1.2691 28.0835 1.36886 28.2844 1.57391C28.4854 1.77896 28.5858 2.0228 28.5858 2.31098C28.5858 2.59915 28.4854 2.843 28.2844 3.04804C28.0835 3.25309 27.8378 3.35285 27.5531 3.35285C27.2685 3.35285 27.0228 3.25309 26.8219 3.04804ZM26.6935 14.6694V5.35901L28.3291 4.58869H28.4128V13.899L26.7772 14.6694H26.6935Z"
              fill="white"
            />
            <path
              d="M31.4272 14.6693V6.85531C31.4272 6.09053 31.9631 5.50863 33.0348 5.09853C33.6154 4.87686 34.2685 4.76602 34.9942 4.76602C35.7199 4.76602 36.3786 4.87686 36.9535 5.09853C38.0253 5.50863 38.5612 6.09607 38.5612 6.85531V13.899L36.9256 14.6693H36.8419V7.15457C36.8419 6.87193 36.6633 6.6558 36.306 6.51171C35.9487 6.36762 35.5077 6.29558 34.9942 6.29558C34.4806 6.29558 34.0396 6.36762 33.6824 6.51171C33.3251 6.6558 33.1465 6.86639 33.1465 7.15457V13.899L31.5109 14.6693H31.4272V14.6693Z"
              fill="white"
            />
            <path
              d="M41.1457 12.4083V6.85531C41.1457 6.09053 41.6816 5.50863 42.7534 5.09853C43.3339 4.87686 43.987 4.76602 44.7127 4.76602C45.4384 4.76602 46.0971 4.87686 46.672 5.09853C47.7438 5.51972 48.2797 6.10715 48.2797 6.85531V15.9052C48.2797 16.67 47.7438 17.2519 46.672 17.662C46.0915 17.8836 45.4384 17.9945 44.7127 17.9945H42.0388V17.9113L42.7254 16.4594H44.7127C45.2263 16.4594 45.6672 16.3873 46.0245 16.2432C46.3818 16.0991 46.5604 15.8886 46.5604 15.6004V14.1983C46.0078 14.3923 45.3937 14.4865 44.7127 14.4865C43.987 14.4865 43.3283 14.3756 42.7534 14.154C41.6816 13.7328 41.1457 13.1453 41.1457 12.3972V12.4083ZM42.865 12.109C42.865 12.3916 43.0436 12.6078 43.4009 12.7519C43.7581 12.8959 44.1991 12.968 44.7127 12.968C45.2263 12.968 45.6728 12.8959 46.0301 12.7519C46.3873 12.6078 46.566 12.3972 46.566 12.109V7.15457C46.566 6.87193 46.3873 6.6558 46.0301 6.51171C45.6728 6.36762 45.2318 6.29558 44.7183 6.29558C44.2047 6.29558 43.7637 6.36762 43.4065 6.51171C43.0492 6.6558 42.8706 6.86639 42.8706 7.15457V12.109H42.865Z"
              fill="white"
            />
            <path
              d="M58.2606 10.5462V12.4083C58.2606 13.162 57.7247 13.7439 56.6529 14.165C56.0724 14.3867 55.4193 14.4975 54.6936 14.4975C53.9679 14.4975 53.3092 14.3867 52.7343 14.165C51.6625 13.7439 51.1266 13.1564 51.1266 12.4083V6.85531C51.1266 6.10161 51.6625 5.51972 52.7343 5.09853C53.3148 4.87686 53.9679 4.76602 54.6936 4.76602C55.4193 4.76602 56.078 4.87686 56.6529 5.09853C57.7247 5.51972 58.2606 6.10715 58.2606 6.85531V7.94706L52.8403 12.0868C52.8403 12.375 53.0189 12.5967 53.3762 12.7408C53.7335 12.8849 54.1744 12.9569 54.688 12.9569C55.2016 12.9569 55.6426 12.8849 55.9998 12.7408C56.3571 12.5967 56.5357 12.3861 56.5357 12.0979V11.3054L58.1713 10.5351H58.255L58.2606 10.5462ZM52.8403 10.3079L56.5413 7.49262V7.27095C56.5413 6.94952 56.3626 6.70568 56.0054 6.54496C55.6481 6.38425 55.2071 6.30112 54.6936 6.30112C54.18 6.30112 53.739 6.37316 53.3818 6.51725C53.0245 6.66134 52.8459 6.87193 52.8459 7.16011V10.3134L52.8403 10.3079Z"
              fill="white"
            />
            <path
              d="M60.8395 14.6693V6.85531C60.8395 6.09053 61.3754 5.50863 62.4472 5.09853C63.0277 4.87686 63.6809 4.76602 64.4065 4.76602C65.1322 4.76602 65.7909 4.87686 66.3659 5.09853C67.4376 5.50863 67.9735 6.09607 67.9735 6.85531V13.899L66.338 14.6693H66.2542V7.15457C66.2542 6.87193 66.0756 6.6558 65.7183 6.51171C65.3611 6.36762 64.9201 6.29558 64.4065 6.29558C63.893 6.29558 63.452 6.36762 63.0947 6.51171C62.7375 6.6558 62.5589 6.86639 62.5589 7.15457V13.899L60.9233 14.6693H60.8395Z"
              fill="white"
            />
            <path
              d="M71.1219 3.04804C70.9209 2.843 70.8204 2.59915 70.8204 2.31098C70.8204 2.0228 70.9209 1.77896 71.1219 1.57391C71.3228 1.36886 71.5684 1.2691 71.8531 1.2691C72.1378 1.2691 72.3834 1.36886 72.5844 1.57391C72.7854 1.77896 72.8858 2.0228 72.8858 2.31098C72.8858 2.59915 72.7854 2.843 72.5844 3.04804C72.3834 3.25309 72.1378 3.35285 71.8531 3.35285C71.5684 3.35285 71.3228 3.25309 71.1219 3.04804ZM70.9935 14.6694V5.35901L72.629 4.58869H72.7128V13.899L71.0772 14.6694H70.9935Z"
              fill="white"
            />
            <path
              d="M75.7216 5.359L77.3572 4.58868H77.4409V12.1035C77.4409 12.3861 77.6196 12.6022 77.9768 12.7463C78.3341 12.8904 78.7751 12.9625 79.2886 12.9625C79.8022 12.9625 80.2432 12.8904 80.6004 12.7463C80.9577 12.6022 81.1363 12.3916 81.1363 12.1035V5.359L82.7719 4.58868H82.8556V12.4027C82.8556 13.1675 82.3197 13.7494 81.248 14.1595C80.6674 14.3812 80.0143 14.492 79.2886 14.492C78.5629 14.492 77.9042 14.3812 77.3293 14.1595C76.2575 13.7494 75.7216 13.162 75.7216 12.4027V5.359Z"
              fill="white"
            />
            <path
              d="M85.4401 14.6693V6.85531C85.4401 6.09053 85.976 5.50863 87.0478 5.09853C87.6283 4.87686 88.2814 4.76602 89.0071 4.76602C89.7328 4.76602 90.3803 4.87686 90.9665 5.09853C91.2511 5.20937 91.5023 5.33129 91.7145 5.4643C91.9266 5.32575 92.1778 5.20383 92.4625 5.09853C93.0542 4.87686 93.7073 4.76602 94.4218 4.76602C95.1363 4.76602 95.8062 4.87686 96.3812 5.09853C97.4529 5.50863 97.9888 6.09607 97.9888 6.85531V13.899L96.3532 14.6693H96.2695V7.15457C96.2695 6.87193 96.0909 6.6558 95.7336 6.51171C95.3764 6.36762 94.9354 6.29558 94.4218 6.29558C93.9083 6.29558 93.4673 6.36762 93.11 6.51171C92.7527 6.6558 92.5741 6.86639 92.5741 7.15457V13.899L90.9386 14.6693H90.8548V7.15457C90.8548 6.87193 90.6762 6.6558 90.3189 6.51171C89.9617 6.36762 89.5207 6.29558 89.0071 6.29558C88.4936 6.29558 88.0526 6.36762 87.6953 6.51171C87.3381 6.6558 87.1594 6.86639 87.1594 7.15457V13.899L85.5239 14.6693H85.4401V14.6693Z"
              fill="white"
            />
            <path
              d="M7.84815 15.0462H3.92947C3.62803 15.0462 3.37683 14.8024 3.37683 14.4976C3.37683 14.1928 3.62245 13.9489 3.92947 13.9489H7.84815C12.0627 13.9489 15.4901 10.5462 15.4901 6.36209V3.82391C15.4901 3.52465 15.7357 3.27527 16.0428 3.27527C16.3498 3.27527 16.5954 3.51911 16.5954 3.82391V6.36763C16.5954 11.1558 12.6711 15.0517 7.84815 15.0517V15.0462Z"
              fill="white"
            />
            <path
              d="M1.16628 9.78696C0.864848 9.78696 0.613647 9.54312 0.613647 9.23831C0.613647 4.45013 4.53791 0.554199 9.35531 0.554199H16.0427C16.3442 0.554199 16.5954 0.798042 16.5954 1.10284C16.5954 1.40765 16.3498 1.65149 16.0427 1.65149H9.35531C5.14078 1.65149 1.71333 5.0542 1.71333 9.23831C1.71333 9.53757 1.46772 9.78696 1.1607 9.78696H1.16628Z"
              fill="white"
            />
            <path
              d="M12.325 7.06589H6.74287C6.44144 7.06589 6.19025 6.82205 6.19025 6.51724C6.19025 6.21244 6.43586 5.9686 6.74287 5.9686H12.325C12.6265 5.9686 12.8777 6.21244 12.8777 6.51724C12.8777 6.82205 12.6321 7.06589 12.325 7.06589Z"
              fill="white"
            />
            <path
              d="M7.41272 12.2974H1.16628C0.864848 12.2974 0.613647 12.0536 0.613647 11.7488C0.613647 11.444 0.859266 11.2001 1.16628 11.2001H7.41272C9.85771 11.2001 11.8505 9.22168 11.8505 6.79434C11.8505 6.49508 12.0962 6.2457 12.4032 6.2457C12.7102 6.2457 12.9558 6.48954 12.9558 6.79434C12.9558 9.82575 10.4717 12.2919 7.4183 12.2919L7.41272 12.2974Z"
              fill="white"
            />
            <path
              d="M1.16628 12.2974C0.864848 12.2974 0.613647 12.0536 0.613647 11.7488V9.23276C0.613647 8.9335 0.859266 8.68411 1.16628 8.68411C1.4733 8.68411 1.71891 8.92795 1.71891 9.23276V11.7488C1.71891 12.048 1.4733 12.2974 1.16628 12.2974Z"
              fill="white"
            />
            <path
              d="M12.325 7.0659C12.1576 7.0659 11.9901 6.98832 11.8785 6.83869C11.6998 6.59485 11.7557 6.25125 11.9957 6.07391L15.7134 3.37502C15.959 3.19768 16.3051 3.24755 16.4838 3.49694C16.6624 3.74078 16.6066 4.08438 16.3665 4.26172L12.6488 6.96061C12.5483 7.03265 12.4367 7.0659 12.325 7.0659V7.0659Z"
              fill="white"
            />
            <path
              d="M6.74845 7.0659C6.58098 7.0659 6.41352 6.98832 6.30187 6.83869C6.12324 6.59485 6.17907 6.25125 6.4191 6.07391L10.1368 3.37502C10.3824 3.19768 10.7285 3.24755 10.9072 3.49694C11.0858 3.74078 11.03 4.08438 10.7899 4.26172L7.07221 6.96061C6.97174 7.03265 6.86009 7.0659 6.74845 7.0659V7.0659Z"
              fill="white"
            />
            <path
              d="M16.0427 4.367H10.4606C10.1592 4.367 9.90796 4.12316 9.90796 3.81836C9.90796 3.51356 10.1536 3.26971 10.4606 3.26971H16.0427C16.3442 3.26971 16.5954 3.51356 16.5954 3.81836C16.5954 4.12316 16.3498 4.367 16.0427 4.367Z"
              fill="white"
            />
            <path
              d="M6.70376 12.2974C6.40232 12.2974 6.15112 12.0536 6.15112 11.7488V6.51724C6.15112 6.21798 6.39674 5.9686 6.70376 5.9686C7.01078 5.9686 7.2564 6.21244 7.2564 6.51724V11.7488C7.2564 12.048 7.01078 12.2974 6.70376 12.2974V12.2974Z"
              fill="white"
            />
            <path
              d="M6.70379 15.0462H1.16628C0.864848 15.0462 0.613647 14.8024 0.613647 14.4975V11.7488C0.613647 11.4495 0.859266 11.2001 1.16628 11.2001H6.70379C7.00522 11.2001 7.25642 11.444 7.25642 11.7488V14.4975C7.25642 14.7968 7.0108 15.0462 6.70379 15.0462ZM1.71891 13.9544H6.15674V12.2974H1.71891V13.9544Z"
              fill="white"
            />
            <path
              d="M16.0427 4.36701C15.7413 4.36701 15.4901 4.12316 15.4901 3.81836V1.10284C15.4901 0.803583 15.7357 0.554199 16.0427 0.554199C16.3498 0.554199 16.5954 0.798042 16.5954 1.10284V3.8239C16.5954 4.12317 16.3498 4.37255 16.0427 4.37255V4.36701Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
